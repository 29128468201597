import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/interiors/img--balcony.jpg").default, caption:"LIVING & BALCONY", x:635, y:200, width:639, height:471 },
    { src:require("../images/interiors/img--living-dining-1.jpg").default, caption:"LIVING & KITCHEN", x:1294, y:200, width:525, height:471 },
    { src:require("../images/interiors/img--living-dining-2.jpg").default, caption:"KITCHEN, LIVING & DINING", x:634, y:692, width:422, height:288 },
    { src:require("../images/interiors/img--section.jpg").default, caption:"KITCHEN, LIVING & DINING", x:1075, y:692, width:745, height:288 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/interiors/bkg--interiors-1.jpg").default} />
        </div>
    )
}