import { useContext, useEffect, useState } from 'react'
import Field from '../components/Field'
import Form from '../components/Form'
import Button from '../components/Button'
import Background from '../components/Background'
import ImageLoader from '../components/ImageLoader'
import Rules from '../helper/rules'
import SubmitButton from '../components/SubmitButton'
import { Context } from '../store/store'
import { useHistory } from "react-router-dom";
import { apiFetch } from '../helper/api'
import sha256 from 'crypto-js/sha256';



const rules = {
    username: [
        Rules.enforce.required("Username is required.")
    ],
    password: [
        Rules.enforce.required("Password is required.")
    ],
}


const defaultFields = {
    username: "",
    password: "",
}


export default function(){
    const [state, setState] = useState({
        fields: defaultFields,
        errors: null as null | Record<keyof typeof defaultFields, string[]>,
        submissions: 0
    })

    const history = useHistory();

    const store = useContext(Context);

    useEffect(function(){
        if(store.state.user.username && store.state.user.password){
            history.replace("/");
        }
    }, [store.state.user.username, store.state.user.password])

    useEffect(function(){
        async function attemptLogin(){
            console.log("Attempting login");

            const hash = sha256(state.fields.username + state.fields.password).toString();
            
            // Store hash in your password DB.
            const response = await apiFetch('https://cms.orchardsveue.com.au/api/app-login.php', {
                "user_name":state.fields.username,
                "password": hash,
            })
            if(response.ok){
                store.actions.user.setUser(state.fields.username, hash);
            }else{
                // store.actions.user.setUser(state.fields.username, hash);
                console.error(response);
                alert("Invalid username or password");
            }            
        }
        if(state.submissions > 0) attemptLogin();
       
    }, [state.submissions]);
    
    function setField(name:keyof typeof defaultFields, value:string){
        setState({
            ...state,
            fields:{
                ...state.fields,
                [name]: value,
            }
        })
    }


    function setErrors(errors:typeof state['errors']){
        setState({
            ...state,
            errors
        })
    }



    async function onSubmitPressed(){
        setState({
            ...state,
            submissions: state.submissions + 1
        })
        /*
        const result = await post({
            "user_name":"aasf",
            "password":"asdf"
        })

        console.log(result);
        console.log(error);
        //const errors = Rules.getErrors(state.fields, rules);
        //setErrors(errors);
        */
    }


    return (
        <>
            <Background>
                <img src={require('../images/login/bkg--agent.jpg').default} />
            </Background>
            <Form>
                <div style={{ position:'relative', top:'84px' }}>
                    <Field name="username" placeholder="Username" value={state.fields.username} errors={state.errors?.username} type="text" onChange={setField} />
                    <Field name="password" placeholder="Password" value={state.fields.password} errors={state.errors?.password} type="password" onChange={setField} />
                    <div style={{ position:'relative', top:'12px' }}>
                        <SubmitButton onClick={onSubmitPressed}/>
                    </div>
                </div>
            </Form>
        </>
    )
}
