import { useState } from 'react'
import ImageLoader from '../components/ImageLoader'
import ImageSelector from '../components/ImageSelector'
import NavMasterPlan from '../components/NavMasterplan'


const items = [
    { name:"Sales Display"              , id:"sales-display"            , icon:require('../images/masterplan/icon--sales-display.png').default,       iconHover:require('../images/masterplan/icon--sales-display-hover.png').default,       overlay:require('../images/masterplan/frg--sales-display.png').default },
    { name:"Outdoor Lagoon Pool"        , id:"outdoor-lagoon-pool"      , icon:require('../images/masterplan/icon--outdoor-lagoon-pool.png').default, iconHover:require('../images/masterplan/icon--outdoor-lagoon-pool-hover.png').default, overlay:require('../images/masterplan/frg--outdoor-lagoon-pool.png').default },
    { name:"Community Centre"           , id:"community-cafe"           , icon:require('../images/masterplan/icon--community-cafe.png').default,      iconHover:require('../images/masterplan/icon--community-cafe-hover.png').default,      overlay:require('../images/masterplan/frg--community-cafe.png').default },
    { name:"Gymnasium"                  , id:"gymnasium"                , icon:require('../images/masterplan/icon--gymnasium.png').default,           iconHover:require('../images/masterplan/icon--gymnasium-hover.png').default,           overlay:require('../images/masterplan/frg--gymnasium.png').default },
    { name:"Indoor Heated Pool & Spa"   , id:"indoor-pool"              , icon:require('../images/masterplan/icon--indoor-pool.png').default,         iconHover:require('../images/masterplan/icon--indoor-pool-hover.png').default,         overlay:require('../images/masterplan/frg--indoor-pool.png').default },
    { name:"Walking Trails"             , id:"walking-trails"           , icon:require('../images/masterplan/icon--walking-trails.png').default,      iconHover:require('../images/masterplan/icon--walking-trails-hover.png').default,      overlay:require('../images/masterplan/frg--walking-trails.png').default },
    { name:"BBQ & Entertaining"         , id:"bbq-and-entertaining"     , icon:require('../images/masterplan/icon--bbq-entertaining.png').default,    iconHover:require('../images/masterplan/icon--bbq-entertaining-hover.png').default,    overlay:require('../images/masterplan/frg--bbq-entertaining.png').default },
    { name:"Outdoor Cinema"             , id:"outdoor-cinema"           , icon:require('../images/masterplan/icon--outdoor-cinema.png').default,      iconHover:require('../images/masterplan/icon--outdoor-cinema-hover.png').default,      overlay:require('../images/masterplan/frg--outdoor-cinema.png').default },
    { name:"The Orchards Parklands"     , id:"the-orchards-parklands"   , icon:require('../images/masterplan/icon--orchard-parklands.png').default,   iconHover:require('../images/masterplan/icon--orchard-parklands-hover.png').default,   overlay:require('../images/masterplan/frg--orchard-parklands.png').default },
    { name:"Castle Hill Country Club"   , id:"castle-hill-country-club" , icon:require('../images/masterplan/icon--castle-hill.png').default,         iconHover:require('../images/masterplan/icon--castle-hill-hover.png').default,         overlay:require('../images/masterplan/frg--castle-hill.png').default },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <>  
            <ImageSelector options={items} selection={selection} />
            <NavMasterPlan items={items} selection={selection} onSelected={setSelection}/>
            <ImageLoader src={require("../images/masterplan/bkg--masterplan.jpg").default} />
        </>
    )
}
