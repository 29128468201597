import ImageLoader from '../components/ImageLoader'
import AutoCarousel from '../components/AutoCarousel'

export default function(){
    return (
        <AutoCarousel>
            <ImageLoader src={require("../images/home/bkg--home-1.jpg").default} />
            <ImageLoader src={require("../images/home/bkg--home-2.jpg").default} />
            <ImageLoader src={require("../images/home/bkg--home-3.jpg").default} />
        </AutoCarousel>
    )
}