import Floorplan from '../components/Floorplan'
import SidePanel from '../components/SidePanel'
import Selection from '../components/Selection'
import { useCallback, useContext, useEffect, useState } from 'react'
import LevelSelector from '../components/LevelSelector'
import coordData from '../data/coord-data'
import planData from '../data/plan-data'
import { fetchSalesData, SalesData } from '../data/sales-data'
import { Context as AppRouteContext } from '../components/AppRoute'
import Loader from '../components/Loader'
import FloorplanKey from '../components/FloorplanKey'
import Plan from '../components/Plan'
import { Context } from '../store/store'


const optionsType = [
    {
        value:{ bedrooms: -1, max:false},
        label:"Any",
    },{
        value:{ bedrooms: 1, max:false},
        label:"1 bedroom",
    },{
        value:{ bedrooms: 1, max:true},
        label:"1 bedroom max",
    },{
        value:{ bedrooms: 2, max:false},
        label:"2 bedroom",
    },{
        value:{ bedrooms: 2, max:true},
        label:"2 bedroom max",
    },{
        value:{ bedrooms: 3, max:"any" as const},
        label:"3 bedroom",
    },{
        value:{ bedrooms: 3, max:true},
        label:"3 bedroom max",
    },{
        value:{ bedrooms: 4, max:"any" as const},
        label:"4 bedroom",
    },
]

const optionsPriceRange = [
    {
        value:"Any",
        label:"Any",
    },{
        value:"$600k - $699k",
        label:"$600k - $699k",
    },{
        value:"$700k - $799k",
        label:"$700k - $799k",
    },{
        value:"$800k - $899k",
        label:"$800k - $899k",
    },{
        value:"$900k - $999k",
        label:"$900k - $999k",
    },{
        value:"$1M - $1.5M",
        label:"$1M - $1.5M",
    },{
        value:"$1.5M - $2M",
        label:"$1.5M - $2M",
    },{
        value:"$2M - $2.5M",
        label:"$2M - $2.5M",
    },{
        value:"$2.5M - $3M",
        label:"$2.5M - $3M",
    },{
        value:"$3M+",
        label:"$3M+",        
    },]



function getMinMax(str:string):[number,number]{
    switch(str){
        case "Any": return [0,9999999];
        case "$600k - $699k": return [600,700];
        case "$700k - $799k": return [700,800];
        case "$800k - $899k": return [800,900];
        case "$900k - $999k": return [900,1000];
        case "$1M - $1.5M": return [1000,1500];
        case "$1.5M - $2M": return [1500,2000];
        case "$2M - $2.5M": return [2000,2500];
        case "$2.5M - $3M": return [2500,3000];
        case "$3M+": return [3000,9999];
        default: throw(new Error("Not found: " + str));
    }
}

const optionsLevels = [
    {
        label:'Rooftop',
        value: 23,
        num: 0,
        level: 23,
    },
    {
        label:'Level 22',
        value:22,
        num: 0,
        level: 22,
    },
    {
        label:'Level 21',
        value:21,
        num: 0,
        level: 21,
    },
    {
        label:'Level 20',
        value:20,
        num: 0,
        level: 20,
    },
    {
        label:'Level 19',
        value:19,
        num: 0,
        level: 19,
    },
    {
        label:'Level 18',
        value:18,
        num: 0,
        level: 18,
    },
    {
        label:'Level 17',
        value:17,
        num: 0,
        level: 17,
    },
    {
        label:'Level 16',
        value:16,
        num: 0,
        level: 16,
    },
    {
        label:'Level 15',
        value:15,
        num: 12,
        level: 15,
    },
    {
        label:'Level 14',
        value:14,
        num: 11,
        level: 14,
    },
    {
        label:'Level 13',
        value:13,
        num: 11,
        level: 13,
    },{
        label:'Level 12',
        value:12,
        num: 14,
        level: 12,
    },{
        label:'Level 11',
        value:11,
        num: 14,
        level: 11,
    },{
        label:'Level 10',
        value:10,
        num: 18,
        level: 10,
    },{
        label:'Level 9',
        value: 9,
        num: 18,
        level: 9,
    },{
        label:'Level 8',
        value: 8,
        num: 18,
        level: 8,
    },{
        label:'Level 7',
        value: 7,
        num: 18,
        level: 7,
    },{
        label:'Level 6',
        value: 6,
        num: 18,
        level: 6,
    },{
        label:'Level 5',
        value: 5,
        num: 18,
        level: 5,
    },{
        label:'Level 4',
        value: 4,
        num: 18,
        level: 4,
    },{
        label:'Level 3',
        value: 3,
        num: 18,
        level: 3,
    },{
        label:'Level 2',
        value: 2,
        num: 13,
        level: 2,
    },{
        label:'Level 1',
        value:1,
        num: 7,
        level: 1,
    },
]

const NUM_LEVELS = 23;
const NUM_FRAMES = 35;


function getImages(){
    const images = new Array<Array<HTMLImageElement>>();
    for(var level=1; level<=NUM_LEVELS; level++){
        const levelImages = new Array<HTMLImageElement>();
        images.push(levelImages);
        var levelStr = level<=9 ? "0"+level : level;
        for(var frame=0; frame<=NUM_FRAMES; frame++){
            var frameStr = frame<=9 ? "0"+frame : frame;
            const image = new Image();
            levelImages.push(image);
            var src = `${process.env.PUBLIC_URL}/floorplans/3D_Floorplans_Level_${levelStr}/3d_Floorplans_L${levelStr}_${frameStr}.jpg`
            setTimeout(setImageSource(image, src), 300);
        }
    }
    return images;
}


function setImageSource(image:HTMLImageElement, src:string){
    return function(){
        image.src = src
    }
}


const preloadObject = {
    src:"floor-plans",
    complete:false,
    onload:function(){}
}


const images = getImages();

var loaded = 0;
images.flat().forEach(img => {
    function didLoad(){
        loaded++;
        if(loaded == images.flat().length){
            preloadObject.complete = true;
            preloadObject.onload();
        }
    }

    if(img.complete){
        didLoad();
    }else{
        img.onload = didLoad
    }
});



export default function(){
    const store = useContext(Context);

    useEffect(() => {
        store.actions.data.requestSales();
    }, [])

    if(!store.state.data.sales) return null;

    return (
        <>
            <StatefulComponent salesData={store.state.data.sales} images={getImages()} />
            <Loader item={preloadObject} />
        </>
    )
}


function StatefulComponent({salesData, images}:{salesData:SalesData, images:Array<Array<HTMLImageElement>>}){
    const [state, setState] = useState({
        selectionType: optionsType[0].value,
        selectionPrice: optionsPriceRange[0].value,
        selectedApartment: null as null|string,
        selectionLevel: 23,
        targetFrame:0,
        selectedPlan: null as null|string,
    })


    function onSelectionType(selectionType:{bedrooms:number, max:boolean|"any"}){
        setState({
            ...state,
            selectionType
        })
    }

    function onSelectionPrice(selectionPrice:string){
        setState({
            ...state,
            selectionPrice
        })
    }

    function onSelectionLevel(selectionLevel:number){
        setState({
            ...state,
            selectionLevel
        })
    }

    function onSelectionApartment(apartmentId:string){
        setState({
            ...state,
            selectedPlan: planData.getPlan(apartmentId),
            selectedApartment: apartmentId,
        })
    }

    function onPlanClose(){
        setState({
            ...state,
            selectedPlan: null,
        })
    }

    function onNext(){
        setState({
            ...state,
            targetFrame: state.targetFrame + 18,
        })
    }

    function onPrev(){
        setState({
            ...state,
            targetFrame: state.targetFrame - 18,
        })
    }

    //const coordData = CoordData.at(state.selectionLevel, modWrap(state.targetFrame, NUM_FRAMES+1));
    //console.log(coordData);    

    const filteredSalesData:SalesData = {};
    Object.keys(salesData).forEach(key => {
        var value:SalesData[number] = salesData[key];
        var price = parseFloat(value.price);
        var minMax = getMinMax(state.selectionPrice);
        var min = minMax[0] * 1000;
        var max = minMax[1] * 1000;
        var passesPrice = price >= min && price < max;
        var passesBedrooms = state.selectionType.bedrooms==-1 || (state.selectionType.bedrooms==parseInt(value.bed) && (state.selectionType.max==value.max || state.selectionType.max=='any'));
        var passes = passesPrice && passesBedrooms;
        if(passes){
            filteredSalesData[key] = value;
        }
    })

    function getNumApartmentsOnLevel(level:number){
        var result = 0;
        for(var i = 0; i < 99; i++){
            var r = i<10 ? "0"+i : i; 
            var apartmentData = filteredSalesData["" + level + r];
            if(apartmentData && apartmentData.saleStatus=="Stock"){
                result++;
            }
        }
        return result;
    }

    const updatedOptions = optionsLevels.map(option => ({
        ...option,
        num:getNumApartmentsOnLevel(option.level),
    }))
    
    return (
        <>  
            <Floorplan onSelectionApartment={onSelectionApartment} salesData={filteredSalesData} images={images} coords={coordData} numLevels={NUM_FRAMES} numFrames={NUM_FRAMES} targetLevel={state.selectionLevel-1} targetFrame={state.targetFrame} onNext={onNext} onPrev={onPrev}/>
            <Plan apartmentId={state.selectedApartment} salesData={salesData} pdf={planData.getPDF(state.selectedPlan)} onClose={onPlanClose} id={ state.selectedPlan }/>
            <SidePanel width={450} justifyContent="flex-start" paddingSide={66} paddingTop={42}>
                <div className="NavFloorPlans">
                    <Selection label="TYPE" options={optionsType} selected={state.selectionType} onSelection={onSelectionType} />
                    <Selection label="PRICE RANGE" options={optionsPriceRange} selected={state.selectionPrice} onSelection={onSelectionPrice} />
                    <LevelSelector selected={state.selectionLevel} options={updatedOptions} onSelection={onSelectionLevel} />
                </div>
            </SidePanel>
        </>
    )
}
