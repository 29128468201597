import React, { useContext, useEffect } from "react"
import AppRoute from './AppRoute'
import { Context } from '../store/store'
import {
    Switch,
    Route,
    useHistory,
} from "react-router-dom";



function appRoute(loginRequired:boolean, preloadRequired:boolean, Component:React.ComponentType<any>):React.ComponentType<any>{
    return function(){
        return (
            <AppRoute loginRequired={loginRequired} preloadRequired={preloadRequired}>
                <Component/>
            </AppRoute>
        )
    }
}



export default function (){
    return (
        <Switch>
            <Route path="/location/lifestyle"       component={ appRoute(true, true, require("../routes/location-lifestyle").default)       } />
            <Route path="/location/aeriel-map"      component={ appRoute(true, true, require("../routes/location-aeriel-map").default)      } />
            <Route path="/location/location-map"    component={ appRoute(true, true, require("../routes/location-location-map").default)    } />
            <Route path="/masterplan"               component={ appRoute(true, true, require("../routes/masterplan").default)               } />
            <Route path="/design"                   component={ appRoute(true, true, require("../routes/design").default)                   } />
            <Route path="/interiors/living"         component={ appRoute(true, true, require("../routes/interiors-living").default)         } />
            <Route path="/interiors/color-schemes"  component={ appRoute(true, true, require("../routes/interiors-color-scheme").default)   } />
            <Route path="/floor-plans"              component={ appRoute(true, true, require("../routes/floor-plans").default)              } />
            <Route path="/views"                    component={ appRoute(true, true, require("../routes/views").default)                    } />
            <Route path="/team"                     component={ appRoute(true, true, require("../routes/team").default)                     } />
            <Route path="/enquire"                  component={ appRoute(true, true, require("../routes/enquire").default)                  } />
            <Route path="/agent-logout"             component={ appRoute(false, true, require("../routes/agent-logout").default)            } />
            <Route path="/agent-login"              component={ appRoute(false, true, require("../routes/agent-login").default)             } />
            <Route path="/"                         component={ appRoute(false, true, require("../routes/home").default )                   } />
        </Switch>
    )
}
