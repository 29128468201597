import coordData from "./coord-data";


const plans:any = {
    '0101': [101],
    '0102': [102],
    '0103': [103],
    '0104': [104], 
    '0201_0301': [201, 301],
    '0202_0302': [202, 302],
    '0203_0310': [203, 310],
    '0204_0309': [204, 309],
    '0303': [303],
    '0304': [304],
    '0305': [305],
    '0306': [306],
    '0307': [307],
    '0308': [308],
    '0401': [401],
    '0402': [402],
    '0403': [403],
    '0404': [404],
    '0405': [405],
    '0406': [406],
    '0407': [407],
    '0408': [408],
    '0409': [409],
    '0410': [410],
    '0501_0601_0701_0801_0901_1001_1101_1201_1301_1401_1501_1601_1701_1801': [501, 601, 701, 801, 901, 1001, 1101, 1201, 1301, 1401, 1501, 1601, 1701, 1801],
    '0502_0602_0702_0802_0902_1002_1102_1202_1302_1402_1502_1602_1702_1802': [502, 602, 702, 802, 902, 1002, 1102, 1202, 1302, 1402, 1502, 1602, 1702, 1802],
    '0503_0603_0703_0803_0903': [503, 603, 703, 803, 903],
    '0504_0604_0704_0804_0904_1004_1104_1204_1304_1404_1504_1604_1704_1804': [504, 604, 704, 804, 904, 1004, 1104, 1204, 1304, 1404, 1504, 1604, 1704, 1804],
    '0505_0605_0705_0805_0905_1005_1105_1205_1305_1405_1505_1605_1705_1805': [505, 605, 705, 805, 905, 1005, 1105, 1205, 1305, 1405, 1505, 1605, 1705, 1805],
    '0506_0606_0706_0806_0906_1006_1106_1206_1306_1406_1506_1606_1706_1806': [506, 606, 706, 806, 906, 1006, 1106, 1206, 1306, 1406, 1506, 1606, 1706, 1806],
    '0507_0607_0707': [507, 607, 707],
    '0508_0608_0708_0808_0908_1008_1108_1208_1308_1408_1508_1608_1708_1808_1905_2005_2105_2205': [508, 608, 708, 808, 908, 1008, 1108, 1208, 1308, 1408, 1508, 1608, 1708, 1808, 1905, 2005, 2105, 2205],
    '0509_0609_0709_0809_0909': [509, 609, 709, 809, 909],
    '0510_0610_0710_0810_0910': [510, 610, 710, 810, 910],
    '0511_0611_0711': [511, 611, 711],
    '0512_0612_0712_0811_0911': [512, 612, 712, 811, 911],
    '0513_0613_0713_0812_0912': [513, 613, 713, 812, 912],
    '0514_0614_0714_0813_0913': [514, 614, 714, 813, 913],
    '0515_0615_0715_0814_0914': [515, 615, 715, 814, 914],
    '0516_0616_0716_0815_0915': [516, 616, 716, 815, 915],
    '0517_0617_0717_0816_0916': [517, 617, 717, 816, 916],
    '0518_0618_0718_0817_0917': [518, 618, 718, 817, 917],
    '0807_0907_1007_1107_1207_1307_1407_1507_1607_1707_1807': [807, 907, 1007, 1107, 1207, 1307, 1407, 1507, 1607, 1707, 1807],
    '1003_1103_1203_1303_1403_1503_1603_1703_1803': [1003, 1103, 1203, 1303, 1403, 1503, 1603, 1703, 1803],
    '1009': [1009],
    '1010': [1010],
    '1901_2001_2101_2201' : [1901, 2001, 2101, 2201],
    '1902_2002_2102_2202' : [1902, 2002, 2102, 2202],
    '1903_2003_2103_2203' : [1903, 2003, 2103, 2203],
    '1904_2004_2104_2204' : [1904, 2004, 2104, 2204],
}


function getPlan(apartmentId:string){
    var planKeys = Object.keys(plans);
    for(var k=0; k < planKeys.length; k++){
        var plan:any = planKeys[k];
        var apartments:number[] = plans[plan];
        var id = parseInt(apartmentId);
        if(apartments.indexOf(id) != -1){
            return plan;
        }
    }
    return null;
}


function getPDF(planId:string|null){
    if(planId == '4444'
    || planId == '4445'
    || planId == '4446'){
        return `SEK25321_Lumia_FloorplanTemplate_Penthouse_V7_FA_LR_${planId}.pdf`
    }else if(planId && planId.indexOf("P")==0){
        return `SEK25321_Lumia_FloorplanTemplate_Standard_V8_FA_LR_${planId}.pdf`
    }else{
        return planId + ".pdf";
    }
}


export default {
    getPlan,
    getPDF
}