import { Provider } from '../store/store'
import Routes from './Routes';
import ScaleContainer from './ScaleContainer'
import Nav from './Nav'
import { HashRouter as Router } from "react-router-dom";
import SalesDataLoader from './SalesDataLoader';


function App() {
	return (
		<div className="App">
			<Provider>
				<ScaleContainer>
					<SalesDataLoader>
						<Router>
							<Nav />
							<Routes />
						</Router>
					</SalesDataLoader>
				</ScaleContainer>
			</Provider>
		</div>
	);
}

export default App;
