type CoordData = {
    title:string,
    x:number,
    y:number,
    max:boolean,
}


function parse(xmlString:string):Record<string, CoordData>{
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlString, 'text/xml');    
    
    var result:Record<string, CoordData> = {};
    var items = xml.getElementsByTagName("item");
    for(var i=0; i < items.length; i++){
        var item = items[i]
        var title = item.getAttribute('title')!
        result[title] = {
            title: title.split(" ").join(""),
            x: parseFloat(item.getAttribute('xPos')!),
            y: parseFloat(item.getAttribute('yPos')!) + 100,
            max: false,
        }
    }

    return result;
}


const coordData = {
    0: {
        ...parse(require('!!raw-loader!./coords/00/level1_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level2_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level3_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level4_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level5_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level6_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level7_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level8_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level9_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level10_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level11_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level12_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level13_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level14_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level15_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level16_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level17_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level18_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level19_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level20_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level21_00.xml').default),
        ...parse(require('!!raw-loader!./coords/00/level22_00.xml').default),

    },
    18: {
        ...parse(require('!!raw-loader!./coords/18/level1_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level2_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level3_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level4_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level5_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level6_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level7_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level8_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level9_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level10_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level11_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level12_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level13_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level14_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level15_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level16_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level17_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level18_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level19_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level20_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level21_18.xml').default),
        ...parse(require('!!raw-loader!./coords/18/level22_18.xml').default),
    }
} as Record<number, Record<string, CoordData>>

export default coordData;