import { useState } from 'react'
import Lightbox from '../components/Lightbox'
import ThumbnailPicker from '../components/ThumbnailPicker'
import ImageLoader from '../components/ImageLoader'


const items = [
    { src:require("../images/interiors/img--bathroom-dark.jpg").default, src2:require("../images/interiors/img--bathroom-light.jpg").default, caption:"Dune", caption2:"Grove", x:95, y:502, width:768, height:480 },
    { src:require("../images/interiors/img--kitchen-1-dark.jpg").default, src2:require("../images/interiors/img--kitchen-1-light.jpg").default, caption:"Dune", caption2:"Grove", x:1060, y:502, width:768, height:480 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={false} />
            <ImageLoader src={require("../images/interiors/bkg--interiors-2.jpg").default} />
        </div>
    )
}