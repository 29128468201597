import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/design/img--design-1.jpg").default, caption:"EXTERIOR BALCONY VIEW", x:710, y:204, width:380, height:244 },
    { src:require("../images/design/img--design-2.jpg").default, caption:"THE ORCHARDS CONCIERGE & LOBBY", x:1110, y:204, width:345, height:244},
    { src:require("../images/design/img--design-3.jpg").default, caption:"EXTERIOR DETAIL", x:1475, y:204, width:345, height:244 },
    { src:require("../images/design/img--design-4.jpg").default, caption:"COMMUNITY INDOOR HEATED POOL & SPA", x:710, y:468, width:380, height:248 },
    { src:require("../images/design/img--design-5.jpg").default, caption:"SPURWAY ROAD ELEVATION", x:1110, y:468, width:710, height:248 },
    { src:require("../images/design/img--design-6.jpg").default, caption:"ELEVATED EXTERIOR VIEW", x:710, y:735, width:380, height:245 },
    { src:require("../images/design/img--design-7.jpg").default, caption:"COMMUNITY CENTRE INTERIOR", x:1110, y:735, width:345, height:245 },
    { src:require("../images/design/img--design-8.jpg").default, caption:"COMMUNITY GYMNASIUM", x:1475, y:735, width:345, height:245 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/design/bkg--design-1.jpg").default} />
        </div>
    )
}